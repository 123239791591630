import { ENUM } from 'conf'
import { func, isArray } from 'tools';
const { OPERATE_TYPE, SERVICE_STATUS } = ENUM;



/**
 * 功能：格式化写跟进记录提交参数
 */
export const handleParams = ( ctx: any, payload: any ): any => {

  const { getters: { serveStatus: { active, next, stop } }, state: { info: { clueId } } } = ctx;

  const { form, form: { servicePhase, followType, copyUserIds }, subForm={} } = { ...payload } as any;

  const defaultValue = {
    accessSource: 'PC', 
    clueId,
  }

  // 如果是服务终止， 当前状态处理
  const resume = OPERATE_TYPE[ '恢复' ];
  form.operateType = {
    [ active._value ]: OPERATE_TYPE[ '只跟进' ],
    [ next._value ]: OPERATE_TYPE[ '推进' ],
    TERMINATION: OPERATE_TYPE[ '流程终止' ],
    SIGNED2: stop ? resume : OPERATE_TYPE[ '变更合同信息' ],
    APPROVED2: stop ? resume : OPERATE_TYPE[ '变更批复信息' ],
    LOAN2: stop ? resume : OPERATE_TYPE[ '变更放款信息' ],
    SUCCESS2: stop ? resume : OPERATE_TYPE[ '变更结算信息' ] 
  }[ servicePhase ] || resume;

  if( followType === 'FALLBACK' ){
    form.operateType = OPERATE_TYPE[ '回退' ];
    form.servicePhase = form.fallback;
  }else if( servicePhase == SERVICE_STATUS[ '服务终止' ] ){
    form.servicePhase = active._value;
  }else{
    form.servicePhase = servicePhase.replace( /\d$/, '' );
  }

  if( isArray( copyUserIds ) ){
    form.copyUserIds = copyUserIds.join( ',' );
  }

  form.fileList = ( form.fileList || [] ).filter( (item: any ) => item.url );

  console.log( form, '------------form-------' );

  form.fallback = null;

  const newParams = { ...defaultValue, ...form, ...subForm };

  return func.filterNull( newParams );

}
